<template>
    <footer class="background-color-footer mt-auto">
        <div class="container py-4 text-center">
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col">
                            <p>
                                Felipe Herrera
                            </p>
                            <a href="mailto: felipe.herrera.u@usach.cl">felipe.herrera.u@usach.cl</a>
                        </div>
                        <div class="col">
                            <a href="https://github.com/fherreralab">
                                <font-awesome-icon icon="fa-brands fa-github" /> fherreralab</a>
                                <br>
                            <a href="https://twitter.com/faherreraur">
                                <font-awesome-icon icon="fa-brands fa-twitter" /> faherreraur</a>
                        </div>

                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <p>
                            Molecular Quantum Technology Group at
                        </p>
                    </div>
                    <div class="row">
                        <p>
                            Universidad de Santiago de Chile.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
.background-color-footer {
    background-color: #0B518E;
}

p {
    color: #CACACA;
}

a {
    color: #849DC9;
    text-decoration: none;
}
</style>