<template>
    <div class="container p-5">
        <div class="px-5 text-start">
            <h1>Milennium Institute</h1>
            <p>
                <a href="https://www.iniciativamilenio.cl/en/miro-2/">The Millenium Institute for Research in Optics</a>
                (MIRO) is a government-funded collaboration between
                researchers at five Chilean universities dedicated to study fundamental and applied science enabled by
                classical and quantum optics.
            </p>
    
            <h3>Novel Quantum Light Sources</h3>
    
            <p>
                In collaboration with the Nanosynthesis Laboratory led by <a
                    href="https://scholar.google.cl/citations?user=j_0nksgAAAAJ&hl=en">Prof. Dinesh Singh</a> (USACH) and
                the Quantum
                Communication Laboratory of <a href="https://scholar.google.com/citations?user=aJHQ4PwAAAAJ">Prof. Gustavo
                    Lima</a> (UdeC), our group is contributing to the development of
                <router-link to='/mof-nlo'>sources of entangled photons based on metal-organic framework
                    materials.</router-link>
            </p>
    
            <p>
                Quantum light can be used to implement:
            </p>
    
            <ul>
                <li>
                    quantum key distribution for secure communications.
                </li>
                <li>
                    fundamental tests of quantum mechanics.
                </li>
                <li>
                    spectroscopic measurements beyond the standard quantum limit of precision.
                </li>
                <li>
                    protocols for multi-dimensional quantum information processing.
                </li>
            </ul>
        </div>
    </div>

</template>